/* eslint-disable @typescript-eslint/no-explicit-any */
import { Client, StompHeaders } from '@stomp/stompjs';

import { WebSocketError } from '@chroma-x/common/core/error';
import { JsonTransportValue } from '@chroma-x/common/core/json';
import { L10n } from '@chroma-x/common/core/l10n';
import { Nullable, trimFromRight } from '@chroma-x/common/core/util';
import { uuid } from '@chroma-x/common/core/uuid';

import {
	RemoveEventListenerCallback,
	WebsocketErrorEventListener,
	WebsocketMessageEventListener,
	WebsocketMessageSubscription,
	WebsocketSimpleEventListener
} from './websocket-events';
import { WebsocketStatus } from './websocket-status';

type ListenerId = string;

/**
 * WebsocketConnector class is responsible for managing the WebSocket connection.
 * It provides methods to connect, subscribe, unsubscribe, close the connection,
 * and handle various events.
 */
export class WebsocketConnector {

	/**
	 * Flag to enable/disable XSS defense.
	 */
	public static xssDefenseEnabled = true;

	private brokerUri: Nullable<string> = null;

	private stompClient: Nullable<Client> = null;

	private webSocketState: WebsocketStatus = WebsocketStatus.IDLE;

	private openListeners: Map<ListenerId, WebsocketSimpleEventListener> = new Map();

	private closeListeners: Map<ListenerId, WebsocketSimpleEventListener> = new Map();

	private errorListeners: Map<ListenerId, WebsocketErrorEventListener> = new Map();

	private subscriptions: Map<string, Map<ListenerId, WebsocketMessageSubscription<any>>> = new Map();

	private pendingSubscriptions: Map<string, Map<ListenerId, WebsocketMessageEventListener<any>>> = new Map();

	/**
	 * Connects to the WebSocket broker.
	 *
	 * @param brokerUri - The URI of the WebSocket broker.
	 * @param connectOtp - A function to get the OTP.
	 * @param debugMode - Flag to enable debug mode.
	 */
	public connect(brokerUri: string, connectOtp: () => Promise<Nullable<string>>, debugMode = false): void {
		this.brokerUri = brokerUri;
		if (this.stompClient === null) {
			this.stompClient = new Client({
				brokerURL: this.brokerUri,
				connectionTimeout: 500,
				reconnectDelay: 1000,
				heartbeatIncoming: 20000,
				heartbeatOutgoing: 20000,
				onConnect: () => {
					this.webSocketState = WebsocketStatus.OPENED;
					this.processPendingSubscriptions();
					this.callOpenListeners();
				},
				onDisconnect: () => {
					this.webSocketState = WebsocketStatus.CLOSED;
					this.callCloseListeners();
				},
				onWebSocketClose: () => {
					this.webSocketState = WebsocketStatus.CLOSED;
					this.callCloseListeners();
				},
				onStompError: () => {
					this.callErrorListeners();
				},
				onWebSocketError: (event) => {
					this.callErrorListeners(event);
				},
				beforeConnect: async (): Promise<void> => {
					if (this.stompClient !== null) {
						let otp = await connectOtp();
						if (otp === null) {
							this.stompClient.connectionTimeout = 0;
							this.stompClient.reconnectDelay = 0;
							otp = '';
						}
						const connectHeaders: StompHeaders = {
							'Accept-Language': L10n.effectiveLocale() ?? '',
							'X-Local-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
							// eslint-disable-next-line @typescript-eslint/naming-convention
							'Accept': 'application/json',
							'X-Connect-Otp': otp
						};
						this.stompClient.connectHeaders = connectHeaders;
					}
				}
			});

			if (debugMode) {
				this.stompClient.logRawCommunication = true;
				this.stompClient.debug = (message) => {
					console.info(message);
				};
			}
		}

		if (!this.stompClient.active) {
			this.stompClient.activate();
		}
	}

	/**
	 * Subscribes to a topic.
	 *
	 * @param topic - The topic to subscribe.
	 * @param listener - The event listener.
	 * @param listenerId - The ID of the listener.
	 * @returns The ID of the listener.
	 */
	public subscribe<Topic extends string>(
		topic: Topic,
		listener: WebsocketMessageEventListener<any>,
		listenerId?: ListenerId
	): ListenerId {

		listenerId = listenerId ?? uuid();

		if (!this.isOpened()) {
			if (!this.pendingSubscriptions.has(topic)) {
				this.pendingSubscriptions.set(topic, new Map());
			}

			const pendingSubscriptionTopic = this.pendingSubscriptions.get(topic) ?? null;
			if (pendingSubscriptionTopic === null) {
				throw new WebSocketError('Pending subscription topic not found');
			}

			if (pendingSubscriptionTopic.has(listenerId)) {
				return listenerId;
			}

			pendingSubscriptionTopic.set(listenerId, listener);
			return listenerId;
		}

		if (this.stompClient === null) {
			throw new WebSocketError('Socket unavailable');
		}

		if (!this.subscriptions.has(topic)) {
			this.subscriptions.set(topic, new Map());
		}

		const subscriptionTopic = this.subscriptions.get(topic) ?? null;
		if (subscriptionTopic === null) {
			throw new WebSocketError('Subscription topic not found');
		}

		if (subscriptionTopic.has(listenerId)) {
			return listenerId;
		}

		try {
			const stompSubscription = this.stompClient.subscribe(
				topic,
				(message) => {
					if (WebsocketConnector.xssDefenseEnabled) {
						const origin = new URL(this.brokerUri ?? '').origin;
						const remoteOrigin = trimFromRight(message.headers?.origin ?? '', '/');
						if (origin.toLowerCase() !== remoteOrigin.toLowerCase()) {
							this.close();
							throw new WebSocketError(`WebSocket XSS violation. Incoming message from invalid origin ${message.headers['origin']} detected. Only messages from ${origin} are handled.`);
						}
					}
					try {
						const parsedMessage = JSON.parse(message.body);
						this.callTopicListeners(topic, parsedMessage);
					} catch (e) {
						throw new WebSocketError('Unexpected message format');
					}
				}
			);

			subscriptionTopic.set(listenerId, {
				eventListener: listener,
				stompSubscription
			});

			return listenerId;
		} catch (e) {
			throw new WebSocketError('Subscription failed', undefined, e as Error);
		}
	}

	/**
	 * Unsubscribes from a topic.
	 *
	 * @param topic - The topic to unsubscribe.
	 * @param subscriptionId - The ID of the subscription.
	 */
	public unsubscribe<Topic extends string>(topic: Topic, subscriptionId: string): void {
		const subscription = this.subscriptions.get(topic) ?? null;
		if (subscription !== null) {
			subscription.get(subscriptionId)?.stompSubscription.unsubscribe();
			subscription.delete(subscriptionId);
		}
		const pendingSubscription = this.pendingSubscriptions.get(topic) ?? null;
		if (pendingSubscription !== null) {
			pendingSubscription.delete(subscriptionId);
		}
	}

	/**
	 * Unsubscribes from all topics.
	 */
	public unsubscribeAll(): void {
		for (const topic of this.subscriptions.keys()) {
			const subscription = this.subscriptions.get(topic) ?? null;
			if (subscription === null) {
				continue;
			}
			for (const listenerId of subscription.keys()) {
				this.unsubscribe(topic, listenerId);
			}
		}
	}

	/**
	 * Closes the WebSocket connection.
	 */
	public close(): void {
		this.unsubscribeAll();
		void this.stompClient?.deactivate();
		this.webSocketState = WebsocketStatus.CLOSED;
	}

	/**
	 * Gets the current state of the WebSocket connection.
	 *
	 * @returns The state of the WebSocket connection.
	 */
	public getState(): WebsocketStatus {
		return this.webSocketState;
	}

	/**
	 * Checks if the WebSocket connection is opened.
	 *
	 * @returns True if the WebSocket connection is opened, false otherwise.
	 */
	public isOpened(): boolean {
		return this.getState() === WebsocketStatus.OPENED;
	}

	/**
	 * Adds an open listener.
	 *
	 * @param listener - The event listener.
	 * @returns A function to remove the listener.
	 */
	public addOpenListener(listener: WebsocketSimpleEventListener): RemoveEventListenerCallback {
		const listenerId = uuid();
		this.openListeners.set(listenerId, listener);
		return () => {
			this.removeOpenListener(listenerId);
		};
	}

	/**
	 * Removes an open listener.
	 *
	 * @param listenerId - The ID of the listener to remove.
	 */
	public removeOpenListener(listenerId: string): void {
		if (this.openListeners.has(listenerId)) {
			this.openListeners.delete(listenerId);
		}
	}

	/**
	 * Adds a close listener.
	 *
	 * @param listener - The listener to add.
	 * @returns A function to remove the listener.
	 */
	public addCloseListener(listener: WebsocketSimpleEventListener): RemoveEventListenerCallback {
		const listenerId = uuid();
		this.closeListeners.set(listenerId, listener);
		return () => {
			this.removeCloseListener(listenerId);
		};
	}

	/**
	 * Removes a close listener.
	 *
	 * @param listenerId - The ID of the listener to remove.
	 */
	public removeCloseListener(listenerId: string): void {
		if (this.closeListeners.has(listenerId)) {
			this.closeListeners.delete(listenerId);
		}
	}

	/**
	 * Adds an error listener.
	 *
	 * @param listener - The listener to add.
	 * @returns A function to remove the listener.
	 */
	public addErrorListener(listener: WebsocketErrorEventListener): RemoveEventListenerCallback {
		const listenerId = uuid();
		this.errorListeners.set(listenerId, listener);
		return () => {
			this.removeErrorListener(listenerId);
		};
	}

	/**
	 * Removes an error listener.
	 *
	 * @param listenerId - The ID of the listener to remove.
	 */
	public removeErrorListener(listenerId: string): void {
		if (this.errorListeners.has(listenerId)) {
			this.errorListeners.delete(listenerId);
		}
	}

	private processPendingSubscriptions(): void {
		for (const topic of this.pendingSubscriptions.keys()) {
			const pendingSubscription = this.pendingSubscriptions.get(topic) ?? null;
			if (pendingSubscription === null) {
				continue;
			}
			for (const listenerId of pendingSubscription.keys()) {
				const listener = pendingSubscription.get(listenerId) ?? null;
				if (listener === null) {
					continue;
				}
				pendingSubscription.delete(listenerId);
				this.subscribe(topic, listener, listenerId);
			}
		}
	}

	private callOpenListeners(): void {
		for (const listener of this.openListeners.values()) {
			listener();
		}
	}

	private callCloseListeners(): void {
		for (const listener of this.closeListeners.values()) {
			listener();
		}
	}

	private callErrorListeners(error?: Event): void {
		for (const listener of this.errorListeners.values()) {
			listener(error);
		}
	}

	private callTopicListeners<Topic extends string, Message extends JsonTransportValue>(
		topic: Topic,
		message: Message
	): void {
		const subscription = this.subscriptions.get(topic) ?? null;
		if (subscription !== null) {
			for (const listener of subscription.values()) {
				listener.eventListener(message);
			}
		}
	}

}
