import { Component, Prop, h, Fragment, State } from '@stencil/core';

import { L10n } from '@chroma-x/common/core/l10n';
import { Maybe, Nullable } from '@chroma-x/common/core/util';

import { format } from '../../utils/utils';

@Component({
	tag: 'my-component',
	styleUrl: 'my-component.scss',
	shadow: true
})
export class MyComponent {

	private removeSelectedLocaleChangeListener: Maybe<() => void>;

	/**
	 * The locale currently used
	 */
	@State() locale: Nullable<string> = L10n.selectedLocale();

	/**
	 * The first name
	 */
	@Prop() readonly first: string;

	/**
	 * The middle name
	 */
	@Prop() readonly middle: string;

	/**
	 * The last name
	 */
	@Prop() readonly last: string;

	connectedCallback() {
		this.removeSelectedLocaleChangeListener = L10n.addSelectedLocaleChangeListener((locale) => {
			this.locale = locale;
		});
	}

	disconnectedCallback() {
		if (this.removeSelectedLocaleChangeListener) {
			this.removeSelectedLocaleChangeListener();
		}
	}

	private getText(): string {
		return format(this.first, this.middle, this.last);
	}

	render() {
		return (
			<Fragment>
				<div>Hello, World! I'm {this.getText()}</div>
				<div>{L10n.translate('stencil-ui-primitive.test')}</div>
			</Fragment>
		);
	}

}
