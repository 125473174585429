/* eslint-disable jsdoc/require-param, jsdoc/require-returns, @stylistic/ts/member-delimiter-style */
/**
 * This file is generated! Changes will be lost on generation!
 * @see /libs/common/domain/okr/api-spec/tspconfig.yml
 */
import { createMethodCallDelayProxy } from '@chroma-x/common/core/proxy';
import { Optional } from '@chroma-x/common/core/util';

import { ObjectiveHistoryServiceApiClient } from '../api-client';
import { ObjectiveHistoryServiceApiClientInterface } from '../api-client-interface';
import { ObjectiveHistoryServiceApiClientMock } from '../api-client-mock';

export class ObjectiveHistoryServiceApiClientFactory {
	public static create(): ObjectiveHistoryServiceApiClientInterface {
		if (process.env.NX_PUBLIC_OKR_API_MOCKED === 'true') {
			const mockRequestDelay = new Optional<string>(process.env.NX_PUBLIC_API_MOCK_DEFAULT_DELAY).getOrDefault('700');
			return createMethodCallDelayProxy(new ObjectiveHistoryServiceApiClientMock(), parseInt(mockRequestDelay));
		}
		return new ObjectiveHistoryServiceApiClient();
	}
}
