/* eslint-disable jsdoc/require-param, jsdoc/require-returns, @stylistic/ts/member-delimiter-style */
/**
 * This file is generated! Changes will be lost on generation!
 * @see /libs/common/domain/okr/api-spec/tspconfig.yml
 */
import { ManualProgressObjectiveMutateModel, ObjectiveProgressMode } from '@chroma-x/frontend/domain/okr/domain-model';

import { BaseObjectiveMutateRequestModelConverter } from './base-objective-mutate-request-model-converter';
import { ManualProgressObjectiveMutateDto } from '../../../../../dto';

export class ManualProgressObjectiveMutateRequestModelConverter extends BaseObjectiveMutateRequestModelConverter {
	public toDto(model: ManualProgressObjectiveMutateModel): ManualProgressObjectiveMutateDto {
		return {
			...super.toDto(model),
			progressMode: model.progressMode satisfies ObjectiveProgressMode.MANUAL
		};
	}
}
