/* eslint-disable jsdoc/require-param, jsdoc/require-returns, @stylistic/ts/member-delimiter-style */
/**
 * This file is generated! Changes will be lost on generation!
 * @see /libs/common/domain/okr/api-spec/tspconfig.yml
 */

export enum ObjectiveScope {
	COMPANY = 'COMPANY',
	SALES = 'SALES',
	MARKETING = 'MARKETING',
	EXPERIENCE_DESIGN = 'EXPERIENCE_DESIGN',
	VISUAL_DESIGN = 'VISUAL_DESIGN',
	ENGINEERING = 'ENGINEERING'
}
