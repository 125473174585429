import { Maybe } from '@chroma-x/common/core/util';
import { uuid } from '@chroma-x/common/core/uuid';

export type EventHandler<P = unknown, E extends string = string> = (payload: Readonly<P>, event: E) => void;

export type EventSubscriptionId = string;

type EventUnsubscribe = () => void;

export type EventSubscriptionDescription = {
	subscriptionId: EventSubscriptionId,
	unsubscribe: EventUnsubscribe
};

type EventSubscription<P = unknown, E extends string = string> = {
	service: symbol,
	names: Array<E>,
	handler: EventHandler<P>
};

type EventsSubscription<P = unknown> = {
	service: symbol,
	handler: EventHandler<P>
};

/**
 * This function checks if the given subscription is of type EventSubscription.
 * It does this by checking if the 'names' property is present in the subscription object.
 *
 * @param subscription - The subscription object to check.
 * @returns Returns true if the subscription is of type EventSubscription, false otherwise.
 */
const isEventSubscription = (subscription: EventSubscription | EventsSubscription): subscription is EventSubscription => {
	return 'names' in subscription;
};

/**
 * EventBroker is a singleton class that allows services to subscribe and unsubscribe
 * from events. It also allows services to publish events.
 */
export class EventBroker {

	private static instance: Maybe<EventBroker>;

	private subscriptions: Map<string, EventSubscription | EventsSubscription> = new Map();

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	private constructor() {
	}

	/**
	 * Get the singleton instance of the EventBroker.
	 *
	 * @returns The singleton instance of the EventBroker.
	 */
	public static get(): EventBroker {
		if (!this.instance) {
			this.instance = new EventBroker();
		}
		return this.instance;
	}

	/**
	 * Subscribe to a specific event.
	 *
	 * @param service - The service symbol.
	 * @param name - The event name.
	 * @param eventHandler - The event handler.
	 *
	 * @returns A description of the subscription.
	 */
	public subscribe<P = unknown, E extends string = string>(
		service: symbol,
		name: E,
		eventHandler: EventHandler<P>
	): EventSubscriptionDescription {
		const subscriptionId = uuid();
		this.subscriptions.set(subscriptionId, {
			service,
			names: [name],
			handler: eventHandler as EventHandler
		});
		return {
			subscriptionId,
			unsubscribe: () => this.unsubscribe(subscriptionId)
		};
	}

	/**
	 * Subscribe to any of the specified events.
	 *
	 * @param service - The service symbol.
	 * @param names - The event names.
	 * @param eventHandler - The event handler.
	 *
	 * @returns A description of the subscription.
	 */
	public subscribeAny<P = unknown, E extends string = string>(
		service: symbol,
		names: Array<E>,
		eventHandler: EventHandler<P>
	): EventSubscriptionDescription {
		const subscriptionId = uuid();
		this.subscriptions.set(subscriptionId, {
			service,
			names,
			handler: eventHandler as EventHandler
		});
		return {
			subscriptionId,
			unsubscribe: () => this.unsubscribe(subscriptionId)
		};
	}

	/**
	 * Subscribe to all events.
	 *
	 * @param service - The service symbol.
	 * @param eventHandler - The event handler.
	 *
	 * @returns A description of the subscription.
	 */
	public subscribeAll<P = unknown>(
		service: symbol,
		eventHandler: EventHandler<P>
	): EventSubscriptionDescription {
		const subscriptionId = uuid();
		this.subscriptions.set(subscriptionId, {
			service,
			handler: eventHandler as EventHandler
		});
		return {
			subscriptionId,
			unsubscribe: () => this.unsubscribe(subscriptionId)
		};
	}

	/**
	 * Unsubscribe from an event.
	 *
	 * @param subscriptionId - The ID of the subscription.
	 */
	public unsubscribe(subscriptionId: EventSubscriptionId) {
		this.subscriptions.delete(subscriptionId);
	}

	/**
	 * Publish an event.
	 *
	 * @param service - The service symbol.
	 * @param name - The event name.
	 * @param payload - The event payload.
	 */
	public publish<P = unknown, E extends string = string>(service: symbol, name: E, payload: P) {
		for (const subscription of this.subscriptions.values()) {
			if (isEventSubscription(subscription)) {
				if (subscription.service === service && subscription.names.includes(name)) {
					subscription.handler({ ...payload } as Readonly<P>, name);
				}
			} else {
				if (subscription.service === service) {
					subscription.handler({ ...payload } as Readonly<P>, name);
				}
			}
		}
	}

}
