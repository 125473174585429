import Timezone from 'timezone-enum';

import { createDateFromMaybeTimestamp, Timestamp } from '@chroma-x/common/core/data-type';
import { getMorningOfDay, getCalendarDay, getToday, Nullable, Optional } from '@chroma-x/common/core/util';

import { DayNameFormat, FuzzyDayName, MonthNameFormat, YearFormat } from './l10n.enums';
import { Locale } from './locale';

type RemoveSelectedLocaleChangeListener = () => void;
type SelectedLocaleChangedHandler = (selectedLocale: Nullable<string>) => void;

/**
 * Class L10n:
 * This class provides a way to handle localization in the application.
 * It allows for the addition, selection, and retrieval of locales.
 * It also provides methods for formatting dates, times, and numbers.
 */
export class L10n {

	private static locales: Record<string, Locale> = {};

	private static defaultLocaleIdentifier: string;

	private static selectedLocaleIdentifier: string;

	private static selectedTimezoneIdentifier: Nullable<Timezone> = null;

	private static selectedLocaleChangeListener: Array<SelectedLocaleChangedHandler> = [];

	/**
	 * Static method addLocale:
	 * Adds a new locale to the list of locales.
	 * @param locale The locale to add.
	 */
	public static addLocale(locale: Locale): void {
		this.locales[locale.identifier.toLowerCase()] = locale;
	}

	/**
	 * Static method hasLocale:
	 * Checks if a locale with the given identifier exists.
	 * @param localeIdentifier The identifier of the locale to check.
	 * @returns True if the locale exists, false otherwise.
	 */
	public static hasLocale(localeIdentifier: string): boolean {
		return localeIdentifier.toLowerCase() in this.locales;
	}

	/**
	 * Static method getLocales:
	 * Returns a map of all the locales.
	 * @returns A map of locales.
	 */
	public static getLocales(): Map<string, Locale> {
		return new Map(Object.entries(this.locales));
	}

	/**
	 * Static method getLocale:
	 * Returns a locale with the given identifier.
	 * @param localeIdentifier The identifier of the locale.
	 * @returns An optional of the locale.
	 */
	public static getLocale(localeIdentifier: string): Optional<Locale> {
		return new Optional<Locale>(this.locales?.[localeIdentifier.toLowerCase()] ?? null);
	}

	/**
	 * Static method setDefaultLocale:
	 * Sets the default locale to the locale with the given identifier.
	 * @param localeIdentifier The identifier of the locale.
	 * @throws Error if the locale does not exist.
	 */
	public static async setDefaultLocale(localeIdentifier: string): Promise<void> {
		if (!this.hasLocale(localeIdentifier)) {
			throw new Error('Locale with identifier ' + localeIdentifier + ' unknown');
		}
		this.defaultLocaleIdentifier = localeIdentifier.toLowerCase();
		await this.getLocale(this.defaultLocaleIdentifier).getOrUndefined()?.select();
	}

	/**
	 * Static method selectLocale:
	 * Selects the locale with the given identifier.
	 * @param localeIdentifier The identifier of the locale.
	 * @throws Error if the locale does not exist.
	 */
	public static async selectLocale(localeIdentifier: string): Promise<void> {
		if (!this.hasLocale(localeIdentifier)) {
			throw new Error('Locale with identifier ' + localeIdentifier + ' unknown');
		}
		this.selectedLocaleIdentifier = localeIdentifier.toLowerCase();
		await this.getLocale(this.selectedLocaleIdentifier).getOrUndefined()?.select();
		this.callSelectedLocaleChangeListener();
	}

	/**
	 * Detects the user's locale based on the navigator's preferred languages.
	 * If the locale is found, it is selected.
	 * @returns True if the locale is found and selected, false otherwise.
	 */
	public static async detectLocale(): Promise<boolean> {
		const fallbackLanguages: Array<string> = [];
		for (let language of this.getNavigatorPreferredLanguages()) {
			language = language.toLowerCase();
			if (language in this.locales) {
				await this.selectLocale(language);
				return true;
			}
			if (language.includes('-')) {
				fallbackLanguages.push(language.substring(0, language.indexOf('-')));
			}
		}

		for (const language of fallbackLanguages) {
			if (language in this.locales) {
				await this.selectLocale(language);
				return true;
			}
		}

		return false;
	}

	/**
	 * Returns the currently selected locale.
	 * @returns The identifier of the selected locale, or null if no locale is selected.
	 */
	public static selectedLocale(): Nullable<string> {
		return this.selectedLocaleIdentifier ?? null;
	}

	/**
	 * Returns the currently effective locale.
	 * If a locale is selected, it is the effective locale.
	 * Otherwise, the default locale is the effective locale.
	 * @returns The identifier of the effective locale, or null if no locale is selected and no default locale is set.
	 */
	public static effectiveLocale(): Nullable<string> {
		return this.selectedLocaleIdentifier ?? this.defaultLocaleIdentifier ?? null;
	}

	/**
	 * Auto-selects the timezone.
	 * The selected timezone is set to null.
	 */
	public static autoSelectTimezone(): void {
		this.selectedTimezoneIdentifier = null;
	}

	/**
	 * Selects the timezone.
	 * @param timezone The timezone to select.
	 */
	public static selectTimezone(timezone: Timezone): void {
		this.selectedTimezoneIdentifier = timezone;
	}

	/**
	 * Returns the currently selected timezone.
	 * @returns The identifier of the selected timezone, or null if no timezone is selected.
	 */
	public static selectedTimezone(): Nullable<Timezone> {
		return this.selectedTimezoneIdentifier;
	}

	/**
	 * Translates a given literal string by replacing placeholders with values.
	 *
	 * @param literal - The literal string to translate.
	 * @param replacements - Optional map of placeholders and their corresponding values.
	 * @returns The translated string or null if the literal is not found.
	 */
	public static translate(literal: string, replacements?: Map<string, string>): string {
		const effectiveLocale = this.getEffectiveLocale();
		if (effectiveLocale === null) {
			return literal;
		}
		return effectiveLocale.translate(literal, replacements) ?? literal;
	}

	/**
	 * Formats a date.
	 * @param date Optional date to format.
	 * @param defaultLocale Optional default locale to use if no effective locale is found.
	 * @param defaultValue Optional default value to return if the date is not provided.
	 * @returns The formatted date.
	 */
	public static formatDate(date?: Date, defaultLocale?: string, defaultValue?: string): string {
		if (date === undefined) {
			return defaultValue ?? '';
		}
		let effectiveLocale = this.effectiveLocale();
		if (effectiveLocale === null) {
			effectiveLocale = defaultLocale ? defaultLocale : null;
		}
		return date.toLocaleDateString(effectiveLocale ?? undefined, {
			month: '2-digit',
			day: '2-digit',
			year: 'numeric',
			timeZone: this.selectedTimezone() ?? undefined
		});
	}

	/**
	 * Formats a timestamp to a date string.
	 *
	 * @param timestamp - The timestamp to format.
	 * @param defaultLocale - The default locale to use if no effective locale is found.
	 * @param defaultValue - The default value to return if the timestamp is not provided.
	 * @returns The formatted date string.
	 */
	public static formatTimestampDate(timestamp?: Timestamp, defaultLocale?: string, defaultValue?: string): string {
		return this.formatDate(createDateFromMaybeTimestamp(timestamp).getOrUndefined(), defaultLocale, defaultValue);
	}

	/**
	 * Formats a date to a time string.
	 *
	 * @param date - The date to format.
	 * @param includeSeconds - Whether to include seconds in the time string.
	 * @param defaultLocale - The default locale to use if no effective locale is found.
	 * @param defaultValue - The default value to return if the date is not provided.
	 * @returns The formatted time string.
	 */
	public static formatTime(date?: Date, includeSeconds = false, defaultLocale?: string, defaultValue?: string): string {
		if (date === undefined) {
			return defaultValue ?? '';
		}
		let effectiveLocale = this.effectiveLocale();
		if (effectiveLocale === null) {
			effectiveLocale = defaultLocale ? defaultLocale : null;
		}
		return date.toLocaleTimeString(effectiveLocale ?? undefined, {
			hour: '2-digit',
			minute: '2-digit',
			second: includeSeconds ? '2-digit' : undefined,
			hour12: false,
			timeZone: this.selectedTimezone() ?? undefined
		});
	}

	/**
	 * Formats a timestamp to a time string.
	 *
	 * @param timestamp - The timestamp to format.
	 * @param includeSeconds - Whether to include seconds in the time string.
	 * @param defaultLocale - The default locale to use if no effective locale is found.
	 * @param defaultValue - The default value to return if the timestamp is not provided.
	 * @returns The formatted time string.
	 */
	public static formatTimestampTime(timestamp?: Timestamp, includeSeconds = false, defaultLocale?: string, defaultValue?: string): string {
		return this.formatTime(createDateFromMaybeTimestamp(timestamp).getOrUndefined(), includeSeconds, defaultLocale, defaultValue);
	}

	/**
	 * Formats a date and time to a date and time string.
	 *
	 * @param date - The date and time to format.
	 * @param includeSeconds - Whether to include seconds in the time string.
	 * @param defaultLocale - The default locale to use if no effective locale is found.
	 * @param defaultValue - The default value to return if the date and time is not provided.
	 * @returns The formatted date and time string.
	 */
	public static formatDateTime(date?: Date, includeSeconds = false, defaultLocale?: string, defaultValue?: string): string {
		if (date === undefined) {
			return defaultValue ?? '';
		}
		return this.formatDate(date, defaultLocale) + ' ' + this.formatTime(date, includeSeconds, defaultLocale);
	}

	/**
	 * Formats a timestamp to a date and time string.
	 *
	 * @param timestamp - The timestamp to format.
	 * @param includeSeconds - Whether to include seconds in the time string.
	 * @param defaultLocale - The default locale to use if no effective locale is found.
	 * @param defaultValue - The default value to return if the timestamp is not provided.
	 * @returns The formatted date and time string.
	 */
	public static formatTimestampDateTime(timestamp?: Timestamp, includeSeconds = false, defaultLocale?: string, defaultValue?: string): string {
		return this.formatDateTime(createDateFromMaybeTimestamp(timestamp).getOrUndefined(), includeSeconds, defaultLocale, defaultValue);
	}

	/**
	 * Formats a date to a day name.
	 * @param date The date to format.
	 * @param defaultValue The default value to return if the date is not provided.
	 * @param format The format of the day name.
	 * @returns The formatted day name.
	 */
	public static formatDayName(date?: Date, defaultValue?: string, format: DayNameFormat = DayNameFormat.LONG): string {
		if (date === undefined) {
			return defaultValue ?? '';
		}
		if (format === DayNameFormat.FUZZY) {
			const day = getMorningOfDay(date);
			const today = getToday();
			const yesterday = getCalendarDay(-1);
			const tomorrow = getCalendarDay(1);
			if (day.getTime() === yesterday.getTime()) {
				return FuzzyDayName.YESTERDAY;
			}
			if (day.getTime() === today.getTime()) {
				return FuzzyDayName.TODAY;
			}
			if (day.getTime() === tomorrow.getTime()) {
				return FuzzyDayName.TOMORROW;
			}
			format = DayNameFormat.LONG;
		}
		const dayName = date.toLocaleString(
			this.effectiveLocale() ?? undefined,
			{
				timeZone: this.selectedTimezone() ?? undefined,
				weekday: format
			}
		);
		return dayName;
	}

	/**
	 * Formats a timestamp to a day name.
	 * @param timestamp The timestamp to format.
	 * @param defaultValue The default value to return if the timestamp is not provided.
	 * @param format The format of the day name.
	 * @returns The formatted day name.
	 */
	public static formatTimestampDayName(timestamp?: Timestamp, defaultValue?: string, format: DayNameFormat = DayNameFormat.LONG): string {
		return this.formatDayName(createDateFromMaybeTimestamp(timestamp).getOrUndefined(), defaultValue, format);
	}

	/**
	 * Formats a date to a month name.
	 * @param date The date to format.
	 * @param defaultValue The default value to return if the date is not provided.
	 * @param format The format of the month name.
	 * @returns The formatted month name.
	 */
	public static formatMonthName(date?: Date, defaultValue?: string, format: MonthNameFormat = MonthNameFormat.LONG): string {
		if (date === undefined) {
			return defaultValue ?? '';
		}
		const monthName = date.toLocaleString(
			this.effectiveLocale() ?? undefined,
			{
				timeZone: this.selectedTimezone() ?? undefined,
				month: format
			}
		);
		return monthName;
	}

	/**
	 * Formats a timestamp to a month name.
	 * @param timestamp The timestamp to format.
	 * @param defaultValue The default value to return if the timestamp is not provided.
	 * @param format The format of the month name.
	 * @returns The formatted month name.
	 */
	public static formatTimestampMonthName(timestamp?: Timestamp, defaultValue?: string, format: MonthNameFormat = MonthNameFormat.LONG): string {
		return this.formatMonthName(createDateFromMaybeTimestamp(timestamp).getOrUndefined(), defaultValue, format);
	}

	/**
	 * Formats a date to a year.
	 * @param date The date to format.
	 * @param defaultValue The default value to return if the date is not provided.
	 * @param format The format of the year.
	 * @returns The formatted year.
	 */
	public static formatYear(date?: Date, defaultValue?: string, format: YearFormat = YearFormat.FULL): string {
		if (date === undefined) {
			return defaultValue ?? '';
		}
		const yearName = date.toLocaleString(
			this.effectiveLocale() ?? undefined,
			{
				timeZone: this.selectedTimezone() ?? undefined,
				year: format
			}
		);
		return yearName;
	}

	/**
	 * Formats a timestamp to a year.
	 * @param timestamp - The timestamp to format.
	 * @param defaultValue - The default value to return if the timestamp is not provided.
	 * @param format - The format of the year.
	 * @returns The formatted year.
	 */
	public static formatTimestampYear(timestamp?: Timestamp, defaultValue?: string, format?: YearFormat): string {
		return this.formatYear(createDateFromMaybeTimestamp(timestamp).getOrUndefined(), defaultValue, format);
	}

	/**
	 * Formats a number.
	 * @param number - The number to format.
	 * @param decimals - The number of decimals to include.
	 * @param defaultLocale - The default locale to use if no effective locale is found.
	 * @param defaultValue - The default value to return if the number is not provided.
	 * @returns The formatted number.
	 */
	public static formatNumber(number?: number, decimals = 2, defaultLocale?: string, defaultValue?: string): string {
		if (number === undefined || isNaN(number)) {
			return defaultValue ?? '';
		}
		let effectiveLocale = this.effectiveLocale();
		if (effectiveLocale === null) {
			effectiveLocale = defaultLocale ? defaultLocale : null;
		}
		return number.toLocaleString(effectiveLocale ?? undefined, {
			minimumFractionDigits: decimals,
			maximumFractionDigits: decimals
		});
	}

	/**
	 * Adds an event listener for selected locale changed events.
	 * @param handler - The callback function to invoke when the event is fired.
	 * @returns A function to remove the added listener.
	 */
	public static addSelectedLocaleChangeListener(handler: SelectedLocaleChangedHandler): RemoveSelectedLocaleChangeListener {
		this.selectedLocaleChangeListener.push(handler);
		return () => this.removeSelectedLocaleChangeListener(handler);
	}

	/**
	 * Removes an event listener for selected locale changed events.
	 * @param handler - The callback function to remove.
	 */
	public static removeSelectedLocaleChangeListener(handler: SelectedLocaleChangedHandler): void {
		this.selectedLocaleChangeListener = this.selectedLocaleChangeListener.filter((h) => {
			return h !== handler;
		});
	}

	private static callSelectedLocaleChangeListener(): void {
		for (const selectedLocaleChangeListener of this.selectedLocaleChangeListener) {
			selectedLocaleChangeListener(this.selectedLocale());
		}
	}

	private static getNavigatorPreferredLanguages(): ReadonlyArray<string> {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		return navigator.languages ? navigator.languages : ([navigator.language] || [navigator.userLanguage]);
	}

	private static getEffectiveLocale(): Nullable<Locale> {
		let effectiveLocale = this.locales[this.selectedLocaleIdentifier] ?? null;
		if (effectiveLocale === null) {
			effectiveLocale = this.locales[this.defaultLocaleIdentifier] ?? null;
		}
		return effectiveLocale;
	}

}
