/* eslint-disable jsdoc/require-param, jsdoc/require-returns, @stylistic/ts/member-delimiter-style */
/**
 * This file is generated! Changes will be lost on generation!
 * @see /libs/common/domain/okr/api-spec/tspconfig.yml
 */
import { ApiError } from '@chroma-x/common/core/error';
import { Maybe, guardProbably, probablyResolves } from '@chroma-x/common/core/util';
import {
	AbstractResponseModelConverter,
	ModelPrimaryKeyConverter,
	NoopModelConverter,
	TimestampConverter
} from '@chroma-x/frontend/core/api-integration';
import {
	BaseObjectiveModel,
	ObjectiveProgressMode,
	ObjectiveScope,
	ObjectiveStatus,
	ObjectiveTimeframe
} from '@chroma-x/frontend/domain/okr/domain-model';

import { BaseObjectiveDto } from '../../../../dto';

export class BaseObjectiveResponseModelConverter extends AbstractResponseModelConverter<BaseObjectiveModel, BaseObjectiveDto> {
	public toModel(dto: BaseObjectiveDto): BaseObjectiveModel {
		return {
			id: new ModelPrimaryKeyConverter().toModel(
				guardProbably(dto.id, new ApiError("Expected property 'id' of 'BaseObjectiveDto' missing."))
			),
			title: guardProbably(dto.title, new ApiError("Expected property 'title' of 'BaseObjectiveDto' missing.")) satisfies string,
			descriptionDocument: dto.descriptionDocument,
			scope: guardProbably(dto.scope, new ApiError("Expected property 'scope' of 'BaseObjectiveDto' missing.")) as ObjectiveScope,
			timeframe: guardProbably(
				dto.timeframe,
				new ApiError("Expected property 'timeframe' of 'BaseObjectiveDto' missing.")
			) as ObjectiveTimeframe,
			dueGoal: new TimestampConverter().toModel(
				guardProbably(dto.dueGoal, new ApiError("Expected property 'dueGoal' of 'BaseObjectiveDto' missing."))
			),
			status: guardProbably(dto.status, new ApiError("Expected property 'status' of 'BaseObjectiveDto' missing.")) as ObjectiveStatus,
			progressMode: guardProbably(
				dto.progressMode,
				new ApiError("Expected property 'progressMode' of 'BaseObjectiveDto' missing.")
			) as ObjectiveProgressMode,
			responsibles: new NoopModelConverter().toArrayOfModel(
				guardProbably(dto.responsibles, new ApiError("Expected property 'responsibles' of 'BaseObjectiveDto' missing."))
			),
			followers: new NoopModelConverter().toArrayOfModel(
				guardProbably(dto.followers, new ApiError("Expected property 'followers' of 'BaseObjectiveDto' missing."))
			),
			createdAt: new TimestampConverter().toModel(
				guardProbably(dto.createdAt, new ApiError("Expected property 'createdAt' of 'BaseObjectiveDto' missing."))
			),
			createdBy: guardProbably(
				dto.createdBy,
				new ApiError("Expected property 'createdBy' of 'BaseObjectiveDto' missing.")
			) satisfies string,
			updatedAt: probablyResolves(dto.updatedAt) ? new TimestampConverter().toModel(dto.updatedAt) : dto.updatedAt,
			updatedBy: dto.updatedBy satisfies Maybe<string>,
			deletedAt: probablyResolves(dto.deletedAt) ? new TimestampConverter().toModel(dto.deletedAt) : dto.deletedAt,
			deletedBy: dto.deletedBy satisfies Maybe<string>
		};
	}
}
