import { MarkdomDocumentDispatcher } from '@markdom/core';
import { ReactElementHandler } from '@markdom/react';
import { useState } from 'react';

import { HypermediaResponse, ModelPrimaryKey } from '@chroma-x/common/core/api-integration';
import { isTimestamp } from '@chroma-x/common/core/data-type';
import { NotFoundError } from '@chroma-x/common/core/error';
import { Nullable } from '@chroma-x/common/core/util';
import { useL10n } from '@chroma-x/frontend/core/react-l10n';
import { ObjectiveServiceApiClientFactory } from '@chroma-x/frontend/domain/okr/api-integration';
import { useObjectiveService } from '@chroma-x/frontend/domain/okr/react-service-provider';

export type ObjectiveProps = {
	id: ModelPrimaryKey
};

export function Objective(props: ObjectiveProps) {

	const { id } = props;

	const l10n = useL10n();
	const service = useObjectiveService();
	const entry = service.query.queryEntity(id)
		.getOrThrow(new NotFoundError(l10n.translate('okr.reactUiDomain.error.objectiveNotFound')));
	const [headResult, setHeadResult] = useState<Nullable<HypermediaResponse>>(null);

	const handleHead = () => {
		const perform = async () => {
			setHeadResult(await ObjectiveServiceApiClientFactory.create().head(id));
		};
		void perform();
	};

	const renderProperties = () => {
		const rows = Object.entries(entry).map(([key, value]) => {
			let displayValue;
			if (isTimestamp(value)) {
				displayValue = l10n.formatTimestampDateTime(value);
			} else if (typeof value === 'object' && 'version' in value) {
				const dispatcher = new MarkdomDocumentDispatcher(value);
				const handler = new ReactElementHandler();
				dispatcher.dispatchTo(handler);
				displayValue = handler.getResult();
			} else {
				displayValue = value;
			}
			return (
				<tr key={key}>
					<td>{key}</td>
					<td>{displayValue}</td>
				</tr>
			);
		});
		return (
			<tbody>
				{rows}
			</tbody>
		);
	};

	const renderHeadResponse = () => {
		if (headResult === null) {
			return;
		}
		const renderRows = () => {
			return Object.entries(headResult).map(([key, value]) => {
				return (
					<tr key={key}>
						<td>{key}</td>
						<td>{value.link}</td>
						<td>{value.title}</td>
						<td>{value.rel}</td>
					</tr>
				);
			});
		};
		return (
			<table>
				<thead>
					<tr>
						<th>Key</th>
						<th>Link</th>
						<th>Title</th>
						<th>Rel</th>
					</tr>
				</thead>
				<tbody>
					{renderRows()}
				</tbody>
			</table>
		);
	};

	return (
		<>
			<button onClick={handleHead}>Perform head request</button>
			{renderHeadResponse()}
			<hr />
			<table>
				<thead>
					<tr>
						<th>Property</th>
						<th>Value</th>
					</tr>
				</thead>
				{renderProperties()}
			</table>
		</>
	);
}
