import { delay as asyncDelay } from '@chroma-x/common/core/util';

/**
 * Returns a `Proxy<ProxyHandlerTarget>` object trapping all calls of functions or methods to delay execution by the given amount of delay.
 *
 * @param object - The object to proxy
 * @param delay - The amount of time to delay the function calls in milliseconds
 * @returns The proxy
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createMethodCallDelayProxy = <ProxyHandlerTarget extends Record<string | symbol, any>>(
	object: ProxyHandlerTarget,
	delay: number
): ProxyHandlerTarget => {
	return new Proxy(object, {
		get(target: ProxyHandlerTarget, property): unknown {
			if (typeof target[property] !== 'function') {
				return target[property];
			}
			return async (...args: Array<unknown>) => {
				try {
					await asyncDelay(delay);
					return target[property](...args);
				} catch (e) {
					console.error('methodCallDelayProxyHandler errored', e);
				}
			};
		}
	});
};
