/* eslint-disable jsdoc/require-param, jsdoc/require-returns, @stylistic/ts/member-delimiter-style */
/**
 * This file is generated! Changes will be lost on generation!
 * @see /libs/common/domain/okr/api-spec/tspconfig.yml
 */
import { AbstractRequestModelConverter } from '@chroma-x/frontend/core/api-integration';
import { FollowerRequestModel } from '@chroma-x/frontend/domain/okr/domain-model';

import { FollowerRequestDto } from '../../../../../dto';
import { IdentityKey } from '../../../cx/common/auth/identity-key';

export class FollowerRequestRequestModelConverter extends AbstractRequestModelConverter<FollowerRequestModel, FollowerRequestDto> {
	public toDto(model: FollowerRequestModel): FollowerRequestDto {
		return {
			follower: model.follower satisfies IdentityKey
		};
	}
}
