import { useEffect, useState } from 'react';

import { AppError } from '@chroma-x/common/core/error';
import { Nullable } from '@chroma-x/common/core/util';
import { EventType, LogLevel } from '@chroma-x/frontend/core/logger';
import { useAuth } from '@chroma-x/frontend/core/react-auth-provider';
import { useEnv } from '@chroma-x/frontend/core/react-environment';
import { useL10n } from '@chroma-x/frontend/core/react-l10n';
import { useLogger } from '@chroma-x/frontend/core/react-logger';

export function RootView() {

	// Access the user auth handler
	const auth = useAuth();

	// Access the user permission handler
	//const permissions = usePermissions<Permission>();

	// Access the runtime environment provided in main.tsx
	const env = useEnv();

	// Access the localization abilities provided in main.tsx
	const l10n = useL10n();

	// Access the logger provided in main.tsx
	const logger = useLogger();
	logger?.logEvent('App render event', { type: EventType.INFO }, 'ui:render', LogLevel.INFO);
	logger?.logMessage('App render message', LogLevel.DEBUG);

	// Enforce a rerender if error is set to test the error handler in main.tsx
	const [error, setError] = useState<Nullable<Error>>(null);
	// Throw the error set
	useEffect(() => {
		if (error !== null) {
			throw error;
		}
	}, [error]);

	// Set the error to be thrown
	const handleThrowError = () => {
		setError(new AppError('Some artificial application error.'));
	};

	// Render a button per known locale to invoke a language switch on click
	const renderLanguageSwitch = () => {
		return Array.from(l10n.getLocales().values()).map((locale) => {
			return (
				<button
					onClick={() => l10n.changeLanguage(locale.identifier)}
					key={locale.identifier}
				>
					{locale.identifier}
				</button>
			);
		});
	};

	// Handle logout
	const handleLogout = () => {
		auth?.unauthenticate();
	};

	return (
		<div>
			<pre>L10n translation example</pre>
			<code>{l10n.translate('reactStarter.appName')}</code>
			<hr />
			<pre>L10n language selection example</pre>
			{renderLanguageSwitch()}
			<hr />
			<pre>Runtime env access example</pre>
			<code>Stage {env.get<string>('stage').getOrDefault('unknown')}</code>
			<hr />
			<pre>Build time env access example</pre>
			<code>Stage {process.env.NX_PUBLIC_SENTRY_ENVIRONMENT}</code>
			<hr />
			<pre>Error handling example</pre>
			<button onClick={handleThrowError}>Throw an AppError</button>
			<hr />
			{/*
				<pre>User permissions</pre>
				<code>{permissions.all().join(', ')}</code>
				<hr />
			*/}
			<button onClick={handleLogout}>Logout</button>
		</div>
	);
}
