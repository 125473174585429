import { useState } from 'react';

import { uuid } from '@chroma-x/common/core/uuid';

/**
 * Custom hook to force a component to re-render.
 *
 * @returns An object with two methods:
 * - invoke: A function that triggers a re-render.
 * - key: A function that returns a unique key for the component.
 */
export const useForceRerender = () => {

	const [rerender, setRerender] = useState<string>(uuid());

	return {
		invoke: (): void => {
			setRerender(uuid());
		},
		key: (): string => {
			return rerender;
		}
	};

};
