import { ReactElement, ReactNode } from 'react';

import {
	AccessDeniedError,
	AuthenticationFailedError,
	AuthenticationRequiredError,
	NotFoundError,
	SimpleError,
	WebSocketError
} from '@chroma-x/common/core/error';
import { Maybe, Nullable } from '@chroma-x/common/core/util';
import { CreateError, DeleteError, FetchError, MutateError } from '@chroma-x/frontend/core/business-error';
import { useForceRerender } from '@chroma-x/frontend/core/react';
import { useAuth } from '@chroma-x/frontend/core/react-auth-provider';
import { ErrorHandler } from '@chroma-x/frontend/core/react-error-handler';
import { useL10n } from '@chroma-x/frontend/core/react-l10n';

export type UiErrorHandlerProps = {
	children: ReactNode
};

/**
 * A component that handles errors and displays a notification.
 *
 * @param props - The component props.
 * @returns The rendered component.
 */
export function UiErrorHandler(props: UiErrorHandlerProps) {

	const { children } = props;

	const auth = useAuth();
	const l10n = useL10n();
	const rerender = useForceRerender();

	const renderError = (error: Error): Nullable<ReactElement> => {

		if (error instanceof WebSocketError) {
			return null;
		}

		if (error instanceof AuthenticationFailedError || error instanceof AuthenticationRequiredError) {
			auth?.reauthenticate();
		}

		const nonRecoverableError =
			error instanceof AccessDeniedError
			|| (error as SimpleError)?.previousError instanceof AccessDeniedError
			|| error instanceof NotFoundError
			|| (error as SimpleError)?.previousError instanceof NotFoundError;

		let heading: string;
		let message: string;
		let notice: Maybe<string>;
		let recoverButton: ReactNode;

		if (error instanceof SimpleError) {
			recoverButton = nonRecoverableError ? null : (
				<button onClick={rerender.invoke}>
					{l10n.translate('reactUiErrorHandler.error.recoverLabel')}
				</button>
			);

			if (error instanceof FetchError) {
				heading = l10n.translate('reactUiErrorHandler.error.fetchHeading');
			} else if (error instanceof MutateError) {
				heading = l10n.translate('reactUiErrorHandler.error.mutateHeading');
			} else if (error instanceof CreateError) {
				heading = l10n.translate('reactUiErrorHandler.error.createHeading');
			} else if (error instanceof DeleteError) {
				heading = l10n.translate('reactUiErrorHandler.error.deleteHeading');
			} else {
				heading = l10n.translate('reactUiErrorHandler.error.generic.heading');
			}

			message = error.message;
			notice = error.previousError?.message;
		} else {
			recoverButton = (
				<button onClick={() => window.location.reload()}>
					{l10n.translate('reactUiErrorHandler.error.generic.resolveAction.label')}
				</button>
			);
			heading = l10n.translate('reactUiErrorHandler.error.generic.heading');
			message = l10n.translate('reactUiErrorHandler.error.generic.message');
			notice = error.message;
		}

		let noticeElement = null;
		if (notice) {
			noticeElement = (
				<pre>
					{l10n.translate('reactUiErrorHandler.error.notice', new Map([['notice', notice]]))}
				</pre>
			);
		}

		return (
			<>
				<h1>{heading}</h1>
				<p>{message}</p>
				{noticeElement}
				{recoverButton}
			</>
		);
	};

	return (
		<ErrorHandler errorComponent={renderError} key={rerender.key()}>
			{children}
		</ErrorHandler>
	);
}
