import process from 'process';

import {
	BrowserOptions,
	reactRouterV6BrowserTracingIntegration,
	browserProfilingIntegration,
	browserTracingIntegration
} from '@sentry/react';
import { Integration } from '@sentry/types';
import { useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

import { SimpleError } from '@chroma-x/common/core/error';

/**
 * Generates the Sentry configuration based on the following env vars and the given `tracePropagationTargets` patterns.
 * - `NX_PUBLIC_SENTRY_DSN`
 * - `NX_PUBLIC_SENTRY_ENVIRONMENT`
 * - `NX_PUBLIC_SENTRY_DEBUG`
 * - `NX_PUBLIC_SENTRY_PROFILING_ENABLED`
 *
 * @param tracePropagationTargets - An array of strings or regular expressions representing the trace propagation targets.
 * @returns The Sentry configuration.
 */
export const sentryConfig = (tracePropagationTargets: Array<string | RegExp> = [/^\//, /^localhost/]): BrowserOptions => {

	const dsn = process.env.NX_PUBLIC_SENTRY_DSN;
	const environment = process.env.NX_PUBLIC_SENTRY_ENVIRONMENT;
	const debug = process.env.NX_PUBLIC_SENTRY_DEBUG === 'true';
	const profilingEnabled = process.env.NX_PUBLIC_SENTRY_PROFILING_ENABLED === 'true';

	const integrations: Array<Integration> = [
		reactRouterV6BrowserTracingIntegration({
			useEffect,
			useLocation,
			useNavigationType,
			createRoutesFromChildren,
			matchRoutes
		}),
		browserTracingIntegration({
			enableInp: true
		})
	];

	if (profilingEnabled) {
		integrations.push(browserProfilingIntegration());
	}

	return {
		dsn,
		integrations,
		debug,
		environment,
		// Capture 100% of the transactions
		tracesSampleRate: 1.0,
		tracePropagationTargets,
		// Capture 100% of the profiled transactions (effective value is tracesSampleRate * profilesSampleRate)
		profilesSampleRate: 1.0,
		// Filter out console output from breadcrumbs
		beforeBreadcrumb: (breadcrumb, _hint) => {
			return breadcrumb.category === 'console' ? null : breadcrumb;
		},
		// Error fingerprinting to support Sentry issue grouping
		beforeSend: function (event, hint) {
			const exception = hint.originalException;
			if (exception instanceof SimpleError) {
				event.fingerprint = [
					'{{ default }}',
					exception.name,
					String(exception.status)
				];
			} else if (exception instanceof Error) {
				event.fingerprint = [
					'{{ default }}',
					exception.name
				];
			}
			return event;
		}
	};

};
