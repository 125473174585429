/* eslint-disable jsdoc/require-param, jsdoc/require-returns, @stylistic/ts/member-delimiter-style */
/**
 * This file is generated! Changes will be lost on generation!
 * @see /libs/common/domain/okr/api-spec/tspconfig.yml
 */
import { baseKeyResultDtoMockData } from './base-key-result-dto-mock-data';
import { ManualProgressKeyResultDto } from '../../../dto';

export const manualProgressKeyResultDtoMockData: ManualProgressKeyResultDto = {
	...baseKeyResultDtoMockData,
	progressMode: 'MANUAL',
	progress: 0.5
};
