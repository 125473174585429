/* eslint-disable jsdoc/require-param, jsdoc/require-returns, @stylistic/ts/member-delimiter-style */
/**
 * This file is generated! Changes will be lost on generation!
 * @see /libs/common/domain/okr/api-spec/tspconfig.yml
 */
import { baseObjectiveDtoMockData } from './base-objective-dto-mock-data';
import { KeyResultBasedProgressObjectiveDto } from '../../../dto';

export const keyResultBasedProgressObjectiveDtoMockData: KeyResultBasedProgressObjectiveDto = {
	...baseObjectiveDtoMockData,
	progressMode: 'KEY_RESULTS',
	progress: 0.5
};
