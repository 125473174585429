import process from 'process';

import { ReactNode, useMemo } from 'react';

import { AppError } from '@chroma-x/common/core/error';
import { Nullable, Optional } from '@chroma-x/common/core/util';
import { PermissionHandler } from '@chroma-x/frontend/core/permission-handler';
import { useAuth } from '@chroma-x/frontend/core/react-auth-provider';

import { permissionContext } from './permission-context';

export type PermissionProviderProps = {
	children: ReactNode
};

/**
 * PermissionProvider is a React component that provides a context for permission checking.
 * It wraps the given children with a PermissionContext.Provider and provides a permissionHandler
 * that can be used to check permissions. If the env var `NX_PUBLIC_CORE_REACT_PERMISSION_PROVIDER_MOCKED`
 * is `true`, a mocked permission provider is used instead of the one using the auth token to derive the
 * permission information.
 *
 * @param props - The props for the PermissionProvider.
 * @param props.children - The children to wrap.
 *
 * @returns The wrapped children.
 */
export const PermissionProvider = (props: PermissionProviderProps) => {

	const { children, ...restProps } = props;

	const permissionProviderMocked = new Optional(process.env.NX_PUBLIC_CORE_REACT_PERMISSION_PROVIDER_MOCKED)
		.getOrThrow(new AppError('Permission provider mock environment flag unavailable'));

	if (permissionProviderMocked === 'true') {
		return (
			<PermissionProviderMock {...restProps}>
				{children}
			</PermissionProviderMock>
		);
	}

	return (
		<PermissionProviderReal {...restProps}>
			{children}
		</PermissionProviderReal>
	);
};

/**
 * PermissionProvider is a React component that provides a context for permission checking.
 * It wraps the given children with a PermissionContext.Provider and provides a permissionHandler
 * that can be used to check permissions.
 *
 * @param props - The props for the PermissionProvider.
 * @param props.children - The children to wrap.
 *
 * @returns The wrapped children.
 */
const PermissionProviderReal = (props: PermissionProviderProps) => {

	const { children } = props;

	const auth = useAuth();
	const authToken = auth?.getToken().get();

	const permissionHandler: Nullable<PermissionHandler<string>> = useMemo(() => {
		const accessToken = authToken?.accessToken;
		if (!accessToken) {
			return null;
		}
		return new PermissionHandler(accessToken);
	}, [authToken]);

	const permissionContextValue = {
		has: (permission: string): boolean => {
			return permissionHandler?.hasPermission(permission) ?? false;
		},
		hasAny: (permissions: Array<string>): boolean => {
			return permissionHandler?.hasAnyPermission(permissions) ?? false;
		},
		hasAll: (permissions: Array<string>): boolean => {
			return permissionHandler?.hasAllPermissions(permissions) ?? false;
		}
	};

	return (
		<permissionContext.Provider value={permissionContextValue}>
			{children}
		</permissionContext.Provider>
	);

};

/**
 * PermissionProviderMock is a React component that provides a mocked context for permission checking.
 * It wraps the given children with a PermissionContext.Provider and provides a mocked permissionHandler
 * that always returns true.
 *
 * @param props - The props for the PermissionProviderMock.
 * @param props.children - The children to wrap.
 *
 * @returns The wrapped children.
 */
const PermissionProviderMock = (props: PermissionProviderProps) => {

	const { children } = props;

	const permissionContextValue = {
		has: (_permission: string): boolean => {
			return true;
		},
		hasAny: (_permissions: Array<string>): boolean => {
			return true;
		},
		hasAll: (_permissions: Array<string>): boolean => {
			return true;
		}
	};

	return (
		<permissionContext.Provider value={permissionContextValue}>
			{children}
		</permissionContext.Provider>
	);

};
