/* eslint-disable jsdoc/require-param, jsdoc/require-returns, @stylistic/ts/member-delimiter-style */
/**
 * This file is generated! Changes will be lost on generation!
 * @see /libs/common/domain/okr/api-spec/tspconfig.yml
 */
export enum KeyResultHistoryServicePermission {
	OKR_KEY_RESULT_HISTORY_READ = 'okr:key-result-history:read'
}

export const keyResultHistoryServicePermissions = [KeyResultHistoryServicePermission.OKR_KEY_RESULT_HISTORY_READ];
