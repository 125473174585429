import { ReactNode } from 'react';

import { ModelPrimaryKey } from '@chroma-x/common/core/api-integration';
import { AppError, NotFoundError } from '@chroma-x/common/core/error';
import { Optional } from '@chroma-x/common/core/util';
import { ObjectiveServicePermission } from '@chroma-x/common/domain/okr/auth';
import { AuthContextValue, useAuth } from '@chroma-x/frontend/core/react-auth-provider';
import { useL10n } from '@chroma-x/frontend/core/react-l10n';
import { PermissionGuard, PermissionGuardMode } from '@chroma-x/frontend/core/react-permission-guard';
import { useObjectiveService } from '@chroma-x/frontend/domain/okr/react-service-provider';

export type ObjectiveAddFollowerFormProps = {
	id: ModelPrimaryKey
};

export function ObjectiveAddFollowerForm(props: ObjectiveAddFollowerFormProps) {

	const { id } = props;

	const l10n = useL10n();
	const auth = new Optional<AuthContextValue>(useAuth());
	const service = useObjectiveService();
	service.query.queryEntity(id)
		.getOrThrow(new NotFoundError(l10n.translate('okr.reactUiDomain.error.objectiveNotFound')));

	const handleAddFollower = () => {
		const tokenDetails = auth.getOrThrow(new AppError('No auth details available')).getTokenDetails().getOrThrow(new AppError('No auth details available'));
		const tokenSubject = new Optional(tokenDetails.sub).getOrThrow(new AppError('No token subject available'));
		void service.command.addFollower(id, tokenSubject);
	};

	const renderPermissionDenied = (permissions: Array<string>, mode: PermissionGuardMode): ReactNode => {
		return (
			<pre>
				Permission denied
				<br />
				Required was {mode} of {permissions.join(', ')}
			</pre>
		);
	};

	return (
		<PermissionGuard
			permissions={[ObjectiveServicePermission.OKR_OBJECTIVE_ADD_FOLLOWER, 'OKR_OBJECTIVE_ADD_FOLLOWERS']}
			mode={PermissionGuardMode.ANY_OF}
			interceptingComponent={renderPermissionDenied}
		>
			<form onSubmit={(e) => e.preventDefault()}>
				<fieldset>
					<legend>Add follower to objective with ID {id}</legend>
					<button onClick={handleAddFollower}>
						Add follower
					</button>
				</fieldset>
			</form>
		</PermissionGuard>
	);
}
