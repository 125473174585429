import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CollectionPaginationResponse, ModelPrimaryKey } from '@chroma-x/common/core/api-integration';
import { Nullable } from '@chroma-x/common/core/util';
import { useL10n } from '@chroma-x/frontend/core/react-l10n';
import { buildRouteUrl } from '@chroma-x/frontend/core/routing';
import { ObjectiveServiceApiClientFactory } from '@chroma-x/frontend/domain/okr/api-integration';
import { useObjectiveService } from '@chroma-x/frontend/domain/okr/react-service-provider';

import { RoutePath } from '../../router/routes';

export type ObjectiveProps = {
	onSelect: (id: ModelPrimaryKey) => void
};

export function ObjectiveCollection(props: ObjectiveProps) {

	const { onSelect } = props;

	const l10n = useL10n();
	const navigate = useNavigate();
	const service = useObjectiveService();
	const [headResult, setHeadResult] = useState<Nullable<CollectionPaginationResponse>>(null);

	const handleRefreshCollection = () => {
		void service.command.refetchCollection();
	};

	const handleRefresh = (entryId: ModelPrimaryKey) => {
		void service.command.refetchCollectionEntity(entryId);
	};

	const handleDelete = (entryId: ModelPrimaryKey) => {
		void service.command.delete(entryId);
	};

	const handleNext = () => {
		void service.command.fetchNextPage();
	};

	const handleHead = () => {
		const perform = async () => {
			setHeadResult(await ObjectiveServiceApiClientFactory.create().headPage());
		};
		void perform();
	};

	const renderItems = () => {
		return service.query.query().map((entry, index) => {
			return (
				<li key={entry.id}>
					#{l10n.formatNumber(index + 1, 0).padStart(3, '0')}: {entry.id}
					<br />
					{entry.title}
					<br />
					{entry.status}
					<br />
					{l10n.formatTimestampDateTime(entry.createdAt)}
					<br />
					Followers: {entry.followers.join(', ')}
					<br />
					<button onClick={() => navigate(buildRouteUrl(RoutePath.OKR_OBJECTIVE, ['objectiveId', entry.id]))}>Details</button>
					<button onClick={() => onSelect(entry.id)}>Select</button>
					<button onClick={() => handleRefresh(entry.id)}>Refresh</button>
					<button onClick={() => handleDelete(entry.id)}>Delete</button>
				</li>
			);
		});
	};

	const renderPaginationButton = () => {
		if (!service.meta.hasNextPage()) {
			return;
		}
		return (<button onClick={handleNext}>Next</button>);
	};

	const renderHeadResponse = () => {
		if (headResult === null) {
			return;
		}
		const renderRows = () => {
			return Object.entries(headResult).map(([key, value]) => {
				return (
					<tr key={key}>
						<td>{key}</td>
						<td>{value}</td>
					</tr>
				);
			});
		};
		return (
			<table>
				<thead>
					<tr>
						<th>Key</th>
						<th>Value</th>
					</tr>
				</thead>
				<tbody>
					{renderRows()}
				</tbody>
			</table>
		);
	};

	return (
		<>
			<button onClick={handleHead}>Perform head request</button>
			{renderHeadResponse()}
			<hr />
			<button onClick={handleRefreshCollection}>Refresh collection</button>
			<hr />
			<ul>
				{renderItems()}
			</ul>
			{renderPaginationButton()}
		</>
	);
}
