import {
	CollectionPaginationResponse,
	FilterCriteria,
	PaginatedCollectionResponse,
	SortCriteria
} from '@chroma-x/common/core/api-integration';
import { KeyResultHistoryServiceApiClientFactory } from '@chroma-x/frontend/domain/okr/api-integration';
import { KeyResultHistoryModel } from '@chroma-x/frontend/domain/okr/domain-model';

export abstract class KeyResultHistoryUseCasesBase {

	public async headCollection(filter?: FilterCriteria<KeyResultHistoryModel>): Promise<CollectionPaginationResponse> {
		return KeyResultHistoryServiceApiClientFactory.create().headPage(1, undefined, filter);
	}

	public async fetchCollection(
		sort?: SortCriteria<KeyResultHistoryModel>,
		filter?: FilterCriteria<KeyResultHistoryModel>
	): Promise<PaginatedCollectionResponse<KeyResultHistoryModel>> {
		return KeyResultHistoryServiceApiClientFactory.create().fetchPage(1, undefined, sort, filter);
	}

	public async fetchPage(
		page = 1,
		sort?: SortCriteria<KeyResultHistoryModel>,
		filter?: FilterCriteria<KeyResultHistoryModel>
	): Promise<PaginatedCollectionResponse<KeyResultHistoryModel>> {
		return KeyResultHistoryServiceApiClientFactory.create().fetchPage(page, undefined, sort, filter);
	}

}
